import {bool, func} from 'prop-types';
import React from 'react';
import {trackButtonClick} from '../util/trackButtonClick';

const HamburgerButton = ({open = false, onToggle}) => {

    const ariaLabel = 'Hamburgermeny';

    return (
        <button
            className={`sb1ds-hamburger-button ${open ? 'sb1ds-hamburger-button--open' : ''}`}
            aria-label={ariaLabel}
            onClick={() => {
                onToggle();
                trackButtonClick({action: `hamburger-menu-${open ? 'close' : 'open'}`, text: ariaLabel});
            }}
        >
          <span className="sb1ds-hamburger-button__icon">
            <span className="sb1ds-hamburger-button__bar" />
          </span>
        </button>
    );
};

HamburgerButton.propTypes = {
    open: bool,
    onToggle: func.isRequired
};

export default HamburgerButton;
