import { graphql, useStaticQuery } from 'gatsby';

export default () => {
    const { site } = useStaticQuery(graphql`
        query SiteMetadataQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `);
    
    return site.siteMetadata;
};
