import React, {} from 'react';
import {Helmet} from 'react-helmet';
import useDarkColorSchemePreference from '../util/useDarkColorSchemePreference';
import {trackButtonClick} from '../util/trackButtonClick';

export default function DarkmodeButton() {
    const {prefersDarkMode, toggleDarkMode} = useDarkColorSchemePreference();
    const title = 'Aktiver darkmode';

    return (<>
        <Helmet>
            <body className={prefersDarkMode ? 'sb1ds-body regard-color-scheme-preference' : 'sb1ds-body'}/>
        </Helmet>
        <div className="ffe-toggle-switch">
            <input
                className="ffe-toggle-switch__input"
                type="checkbox"
                id="darkmode-switch"
                checked={prefersDarkMode}
                onChange={() => {
                    toggleDarkMode();
                    trackButtonClick({action: `darkmode-switch-${prefersDarkMode ? 'off' : 'on'}`, text: title});
                }}
            />
            <label
                className="ffe-toggle-switch__label"
                htmlFor="darkmode-switch"
            >
                <span
                    className="ffe-toggle-switch__label-off"
                    aria-hidden="true"
                >
                    <img src="/img/light-mode-icon.svg" className="sb1ds-darkmode-button__icon"
                         alt="Ikon for lys modus"/>
                </span>
                <span
                    className="ffe-toggle-switch__switch"
                    aria-hidden="true"
                />
                <span
                    className="ffe-toggle-switch__label-on"
                    id="test"
                    aria-hidden="true"
                >
                   <img src="/img/dark-mode-icon.svg"
                        className="sb1ds-darkmode-button__icon"
                        alt="Ikon for mørk modus"/>
                </span>
            </label>
        </div>
    </>);
}
