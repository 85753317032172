import { getGlobalTracker } from '@sb1/web-behavior-tracker';

export const trackButtonClick = ({ text, action }) => {
    try {
        getGlobalTracker().buttonClicked(
            {
                text,
                section: 'designsystem',
                action
            }, { referrer: window.location.referrer }
        );
    } catch (e) {
        console.error(e);
    }
};