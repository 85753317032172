import React, {useState} from 'react';
import {Link, graphql, useStaticQuery} from 'gatsby';
import {useLocation} from '@reach/router';
import Logo from '../../static/img/sparebank1-logo.svg';
import HamburgerButton from '../components/HamburgerButton';
import NavigationList from '../components/NavigationList';
import DarkmodeButton from './DarkmodeButton';
import {trackButtonClick} from '../util/trackButtonClick';

const addPageTitle = ({path, id, context}) => ({
    title: (context.frontmatter && context.frontmatter.title) ? context.frontmatter.title : 'Uten tittel',
    id,
    path
});

// this mutation should be done within the grapqhql query
const buildNavigationData = (navigationNodes, pageNodes, currentPath) => {
    const pages = pageNodes.filter(p => p.context).map(addPageTitle);

    return navigationNodes.map(({id, title, path}) => {
        const children = pages.filter(p => {
            return p.path.startsWith(path);
        });

        if (children.length === 1 && children[0].path === path) {
            return {id, title, path};
        } else {
            const isParentOfCurrentPath = currentPath.startsWith(path);
            return {id, title, isParentOfCurrentPath, children};
        }
    });
};

const Navigation = () => {
    const {pathname: currentPath} = useLocation();
    const [isNavExpanded, setIsNavExpanded] = useState(false);

    const toggleIsNavExpanded = () => setIsNavExpanded(expanded => !expanded);

    const data = useStaticQuery(graphql`
        query NavigationQuery {
            navigation: allNavigasjonJson {
                nodes {
                    id
                    title: name
                    path
                }
            }
            pages: allSitePage(sort: {fields: [context___frontmatter___order, context___frontmatter___title]}) {
                nodes {
                    id
                    path
                    context {
                        frontmatter {
                            title
                        }
                    }
                }
            }
        }
    `);

    const githubSvgTitle = 'SpareBank 1 Designsystem på GitHub';

    return (<nav className={`sb1ds-nav ${isNavExpanded ? 'sb1ds-nav--expanded' : ''}`}>
            <div className="sb1ds-nav__wrapper">
                <Link to="/">
                    <Logo/>
                </Link>

                <HamburgerButton open={isNavExpanded} onToggle={toggleIsNavExpanded}/>

                <div className="sb1ds-nav__container">
                    <div className="sb1ds-nav__inner">
                        <NavigationList
                            items={buildNavigationData(data.navigation.nodes, data.pages.nodes, currentPath)}/>
                    </div>
                    <div className="sb1ds-nav__bottom">
                        <div className="sb1ds-nav__bottom-item">
                            <DarkmodeButton/>
                        </div>
                        <div className="sb1ds-nav__bottom-item">
                            <a
                                href="https://github.com/SpareBank1/designsystem"
                                className="sb1ds-nav__github-link"
                                onClick={() => trackButtonClick({
                                    action: 'github-link', text: githubSvgTitle
                                })}
                                aria-label="SpareBank 1 Designsystem på GitHub"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 41"
                                     className="sb1ds-nav__github-logo" aria-hidden={true}>
                                    <title>{githubSvgTitle}</title>
                                    <defs>
                                        <clipPath id="a">
                                            <path d="M0 0h42v41H0z"/>
                                        </clipPath>
                                    </defs>
                                    <g clipPath="url(#a)">
                                        <path fillRule="evenodd"
                                              d="M20.998.5C9.676.5.494 9.681.494 21.007c0 9.059 5.875 16.745 14.024 19.458 1.025.187 1.399-.446 1.399-.99 0-.487-.017-1.776-.027-3.487-5.704 1.239-6.908-2.749-6.908-2.749-.932-2.369-2.277-3-2.277-3-1.862-1.271.141-1.246.141-1.246 2.058.145 3.141 2.114 3.141 2.114 1.829 3.133 4.8 2.228 5.968 1.703.186-1.325.716-2.228 1.302-2.741-4.553-.518-9.341-2.277-9.341-10.135 0-2.239.799-4.068 2.111-5.502-.211-.519-.915-2.604.202-5.427 0 0 1.72-.552 5.638 2.101 1.635-.455 3.39-.681 5.134-.69 1.742.009 3.495.235 5.133.69 3.915-2.653 5.634-2.101 5.634-2.101 1.119 2.823.415 4.908.205 5.427 1.314 1.434 2.107 3.263 2.107 5.502 0 7.878-4.795 9.612-9.362 10.119.735.633 1.391 1.884 1.391 3.798 0 2.74-.025 4.952-.025 5.624 0 .549.37 1.188 1.41.987 8.142-2.717 14.012-10.398 14.012-19.455 0-2.88-.593-5.62-1.665-8.107C36.699 5.606 29.445.5 20.998.5"/>
                                    </g>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </nav>);
};

export default Navigation;
